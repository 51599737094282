import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { TextType, Typography, Color } from '../../Foundation';
import { Box, BoxProps, mediaQuery } from '../../Layout';

type PropElement =
  | FunctionComponent
  | JSX.Element
  | string
  | number
  | null
  | undefined;

export type CardProps = BoxProps & {
  headingType?: TextType;
  paragraphType?: TextType;
  centerContent?: boolean;
  imageFullWidth?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  image?: PropElement;
  headline?: PropElement;
  text?: PropElement;
  cta?: PropElement;
  link?: { href: string; text: string };
  ribbonText?: PropElement;
};

export const Card: React.FC<CardProps> = ({
  headingType = 'h6',
  paragraphType = 'manchetSmall',
  centerContent = false,
  imageFullWidth = false,
  noBorder = false,
  noPadding = false,
  image,
  text,
  headline,
  cta,
  link,
  color,
  ribbonText,
  ...rest
}) => {
  const isLink = typeof link?.href === 'string';

  return (
    <Container
      centerContent={centerContent}
      noBorder={noBorder}
      as={isLink ? 'a' : 'div'}
      {...link}
      {...rest}
    >
      {ribbonText && <Ribbon>{ribbonText}</Ribbon>}
      {image && (
        <Image imageFullWidth={imageFullWidth} noPadding={noPadding}>
          {image}
        </Image>
      )}
      <Content noPadding={noPadding}>
        <Box flex={1}>
          {headline && (
            <Box marginTop={image ? 0 : 3} marginBottom={4}>
              <Typography type={headingType}>{headline}</Typography>
            </Box>
          )}
          {text && <Typography type={paragraphType}>{text}</Typography>}
        </Box>

        {cta && <Box marginTop={4}>{cta}</Box>}
      </Content>
    </Container>
  );
};

type ContainerProps = {
  centerContent: boolean;
  noBorder: boolean;
  href?: string;
};

const Container = styled(Box)<ContainerProps>`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  text-align: ${(props) => (props.centerContent ? 'center' : 'left')};
  border: solid 0.8px #eeeeee;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  transition: box-shadow 0.1s ease;

  &:hover {
    box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.22);
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
      box-shadow: none;
      transition: none;

      &:hover {
        box-shadow: none;
      }
    `}
`;

const Image = styled.div<CardProps>`
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 30px 25px 0;

      ${mediaQuery.md} {
        padding: 40px 40px 0;
      }
    `}

  ${({ imageFullWidth }) =>
    imageFullWidth &&
    css`
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 10px;

      ${mediaQuery.md} {
        padding-bottom: 15px;
      }
    `}    

  img {
    max-width: 100%;
    height: auto;

    ${({ imageFullWidth }) =>
      imageFullWidth &&
      css`
        display: block;
        width: 100%;
      `}
  }
`;

const Content = styled.div<CardProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 25px 30px;

  ${mediaQuery.md} {
    padding: 20px 40px 40px;
  }

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 20px 0 0 !important;
    `}
`;

const Ribbon = styled.div`
  position: absolute;
  left: 100%;
  top: -35px;
  width: 200px;
  height: 35px;
  background: ${Color.red40};
  color: ${Color.white};
  font-weight: 600;
  transform: translate(-109px) rotate(45deg);
  transform-origin: top left;
  text-align: center;
  line-height: 35px;
`;
